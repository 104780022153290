<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">Social Media</h1>
    </div>
    <div class="title-tabs mt-3">Social Media Field</div>

    <b-container class="no-gutters bg-white py-3">
      <b-row>
        <b-col md="6">
          <InputText
            textFloat="Name"
            placeholder="Name"
            type="text"
            name="social-name"
            v-model="form.name"
            :v="$v.form.name"
            isRequired
            :isValidate="$v.form.name.$error"
          />
        </b-col>
        <!-- {{ form.image_url }} -->
        <b-col md="6">
          <UploadFile
            textFloat="Banner"
            placeholder="Please Choose File"
            format="video"
            :fileName="form.banner"
            v-model="form.banner"
            name="thumbnail"
            text="*Please upload only .png, .jpg image with ratio 1:1 less than 10 MB"
            isRequired
            accept="image/jpeg, image/png"
            v-on:onFileChange="(val) => onImageChange(val, 'banner', 1)"
            :v="$v.form.banner"
            :disabled="isDisable"
            :isValidate="$v.form.banner.$error"
          />
          <!-- :v="$v.form.banner" -->
          <PreviewBox
            v-on:delete="deleteImage('banner')"
            :showPreviewType="0"
            :showPreview="form.banner"
            :disabled="isDisable"
            :isLoadingImage="isImageLoading"
            :ratioType="1"
          />
        </b-col>
      </b-row>
    </b-container>
    <FooterAction routePath="/setting/socialmedia" @submit="saveForm()" />
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import PreviewBox from "@/components/PreviewBox.vue";
export default {
  components: { PreviewBox },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        banner: {
          required,
        },
      },
    };
  },
  data() {
    return {
      form: {
        name: "",
        banner: "",
      },
      isDisable: false,
      isImageLoading: false,
    };
  },
  mounted() {},

  created() {
    if (this.$route.params.id != 0) {
      this.getList();
    }
  },
  destroyed() {},
  methods: {
    async getList() {
      const response = await this.axios(
        `/setting/GetSocialMediaById/${this.$route.params.id}`
      );
      this.form = response.data.detail;
    },
    onImageChange(val, key, ratio) {
      this.isImageLoading = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = async () => {
          // if (mode == "icon") {
          if (val.size > 103384000)
            return this.$swal("This file is too large", {
              icon: "info",
            });
          if (ratio == 1) {
            let message = "Please upload image with ratio 1:1 !";
            var width = parseInt(img.width);
            var height = parseInt(img.height);
            if (parseInt(width) !== parseInt(height)) {
              this.$swal(message, {
                icon: "info",
              });
              this.ratioMatch = false;
              this.isImageLoading = false;
              this.isDisable = false;
              return this.deleteImage(key);
            }
          } else {
            var width = parseInt(img.width) / 16;
            var height = parseInt(img.height) / 9;

            if (parseInt(width) !== parseInt(height)) {
              this.$swal("Please upload image with ratio 16:9 !", {
                icon: "info",
              });
              this.ratioMatch = false;
              this.isImageLoading = false;
              this.isDisable = false;
              return this.deleteImage(key);
            }
          }
          // }

          this.ratioMatch = true;
          this.setUpdataImage(reader.result, key);
        };
        img.src = reader.result;
      };
    },
    deleteImage(key) {
      this.form[key] = "";
      this.isImageLoading = false;
    },
    async saveForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      if (this.$route.params.id == 0) {
        this.$emit("showLoading");
        const res = await this.axios.post(
          `/setting/CreateSocialMedia`,
          this.form
        );

        if (res.data.result) {
          this.successAlert().then(
            (val) => val && this.$router.push("/setting/socialmedia")
          );
        } else {
          this.errorAlert(res.data.message);
        }
        this.$emit("hideLoading");
      } else {
        this.$emit("showLoading");
        this.axios.put(`/setting/UpdateSocialMedia`, this.form).then((res) => {
          if (res.data.result) {
            this.successAlert().then(
              (val) => val && this.$router.push("/setting/socialmedia")
            );
          } else {
            this.errorAlert(res.data.message);
          }
          this.$emit("hideLoading");
        });
      }
    },
    async setUpdataImage(base64, key) {
      var url = await this.saveImagetoDb(base64, false);

      this.isLoadingImage = false;
      this.isDisable = false;

      this.form[key] = url;
      // this.form.banner.is_video = 0;
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
  },
};
</script>
<style>
label.title {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.f-20 {
  font-size: 20px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #a59cfc;
}
.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}
</style>
